@import "src/ui-kit/styles/vars";

.App {
    padding-top: 85px;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;

    &__container {
        width: 100%;
        min-height: calc(100vh - 280px);
        color: #FFFFFF;
        margin-bottom: 56px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

.connect-modal {
    transition: opacity 0.1s ease-in-out 0s;
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100%;
    margin-left: -50vw;
    top: 0;
    left: 50%;
    z-index: 2;
    will-change: opacity;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &__card {
        display: flex !important;
        width: 350px !important;
        flex-direction: column !important;
        padding: 32px 40px !important;

        background-color: rgb(49, 49, 49);
        border-radius: 12px;
        margin: 10px;
        box-sizing: border-box;
        z-index: 5;

        &:before {
            content: 'Connect a wallet';
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
        }
    }

    &__provider {
        &-wrapper {
            cursor: pointer;
            padding: 0 0 8px 0 !important;

            &:first-child {
                padding-top: 32px !important;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
        &-container {
            padding: 10px 16px !important;
            background: $black !important;
            border-radius: 8px !important;
            display: flex !important;
            flex-direction: row !important;
            align-items: center !important;
            transition: background-color 0.1s ease-in-out 0s !important;
            position: relative !important;

            &:hover {
                background-color: rgba(32, 32, 29, 0.8) !important;
            }

            &:after {
                content: url("../../ui-kit/images/arrow-right.svg");
                position: absolute;
                top: 16px;
                right: 16px;
            }

            &--disabled {
                cursor: none;
                pointer-events: none;
                background: $dark !important;
                opacity: 0.7;

                &:after {
                    content: none;
                }
            }
        }
        &-icon {
            width: 28px !important;
            height: 28px !important;
        }
        &-name {
            font-size: 14px !important;
            line-height: 18px !important;
            text-align: left;
            padding-left: 12px;
        }
    }

    &__fade {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0, 0.7);
        z-index: 4;
    }
}
