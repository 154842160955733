@import "../../ui-kit/styles/vars";

.header {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 40px;
    z-index: 10;
    top: 0;
    left: 0;

    background: $black;
    box-shadow: 0 6px 12px 6px rgba(32,32,29,1);

    @media screen and (max-width: 600px) {
        height: 68px;
        padding: 0 16px;

        &__logo {
            height: 40px;
            width: 40px;
        }

        &__link {
            padding: 6px 6px;
            margin-right: 2px;
        }
    }

    &__address {
        cursor: pointer;
        background: #313131;
        border-radius: 40px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 20px;
        width: 120px;
        height: 40px;

        &-text {
            display: block;
        }
        &-disconnect {
            display: none;
        }

        &:hover {
            .header__address-text {
                display: none;
            }
            .header__address-disconnect {
                display: block;
            }
        }
    }

    &__navbar {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__button {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        height: 40px;
        width: 120px;
        cursor: pointer;
        border-radius: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: $black;
        background-clip: padding-box;
        border: solid 1px transparent;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: inherit;
            background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
        }
    }

    &__link {
        height: 40px;
        padding: 8px 10px;
        border-radius: 80px;
        border: 2px solid transparent;
        margin-right: 4px;
        box-sizing: border-box;

        &--selected {
            border: 2px solid $dark-gray;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__links {
        margin-right: 20px;
    }

    &__menu {
        margin-left: 16px;
    }
}
