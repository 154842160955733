@import "../../ui-kit/styles/vars";

.receive-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin-bottom: 25px;

    &--disabled {
        .info-cell__content {
            opacity: 0.5;
        }
    }

    &--detailed {
        #addresses {
            flex-direction: column;
        }

        #tokenAddress, #addresses .info-cell__content {
            font-size: 11px;
        }

        .receive-form__metamask {
            background: none;
            width: 20px;
            height: 20px;
            margin-right: 4px;
        }
    }

    &__switch {
        display: flex;
        align-items: center;
        position: absolute;
        right: 40px;
        top: 102px;

        &__title {
            font-size: 15px;
        }
    }

    &__content {
        background: $black;
        background-clip: padding-box;
        border: solid 1px transparent;
        position: relative;
        border-radius: 24px;
        padding: 32px 34px;
        box-sizing: border-box;
        width: 100%;

        &:before {
            content: '';
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: inherit;
            background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
        }
    }

    &__title {
        font-weight: 500;
        font-size: 32px;
        line-height: 42px;
        padding-bottom: 32px;
        text-align: center;
    }

    &__line {
        display: flex;

        .info-cell:first-child {
            margin-right: 24px;
        }
    }

    &__copy-container {
        .info-cell__content {
            justify-content: space-between;
        }
    }

    &__token-address {
        position: relative;

        a {
            color: white;
        }
        &__buttons {
            display: flex;
            justify-content: center;
        }

        .info-cell__content {
            justify-content: space-between;
        }
    }

    &__metamask {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        height: 28px;
        width: 85px;
        border-radius: 40px;
        margin-right: 24px;

        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: $black;
        cursor: pointer;
    }

    &__button {
        width: 100%;
        height: 58px;
        margin: 0 auto 10px auto;
    }

    &__hash {
        width: 100%;
        max-width: 350px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin: 0 25px;
        padding: 24px 8px 16px 16px;
        box-sizing: border-box;

        a {
            text-decoration-line: underline;
            cursor: pointer;

            background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        &__text {
            display: flex;
        }
    }

    &__error {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #FC7557;
        text-align: center;
        padding-bottom: 8px;

        &:last-child {
            padding-bottom: 0;
        }
    }

    a {
        color: inherit !important;
    }
}

.copy {
    cursor: pointer;
}