@import "../../../../ui-kit/styles/vars";

.token-address {
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    display: flex;
    align-items: center;

    img {
      margin-left: 20px;
      margin-right: -5px;
    }
  }

  &__token-name {
    position: relative;
    left: 17px;
    font-size: 12px;
    top: -14px;
  }
}

.MuiAutocomplete-paper {
  color: white !important;
  background: $dark-gray !important;

  .MuiAutocomplete-noOptions {
    color: $gray1 !important;
  }

  img {
    margin-right: 8px;
  }
}