@import "../../ui-kit/styles/vars";

.gen-url-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 30px 28px 14px 28px;
  background: $dark-gray;
  border-radius: 4px;

  &__title {
    text-align: center;
    padding-bottom: 5px;
  }

  .gen-url {
    margin: 0;
  }

  &__close {
    background: white;
    border-radius: 20px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    padding: 2px;
  }

  .gen-url__text {
    @media screen and (max-width: 500px) {
      justify-content: center;
      flex: 1;
    }
  }

  @media screen and (max-width: 500px) {
    width: 300px;
    padding: 30px 15px 10px;
  }
}