@import "../../styles/vars";

$border: 1px;

.button {
  font-family: 'DM Sans', sans-serif;
  height: 58px;
  cursor: pointer;

  position: relative;
  padding: 5px;
  box-sizing: border-box;

  background: $black;
  background-clip: padding-box;
  border: solid $border transparent;
  border-radius: 80px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    .button__gradient-text {
      background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .button__content {
      background: $dark-gray !important;
    }
  }

  &__gradient-text {
    transition: all 200ms;
    color: $black;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;
    background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    transition: all 200ms;

    width: 100%;
    height: 100%;
    background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
    border-radius: 80px;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;

    .button__gradient-text {
      opacity: 0.5;
    }
  }
}
