@import "../../ui-kit/styles/vars";

.restore-form {
    &__disclaimer {
        width: 570px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        @media screen and (max-width: 600px) {
            font-size: 14px;
            padding: 6px 6px;
            box-sizing: border-box;
            width: 100%;
        }
    }
    &__url {
        background: $dark-gray;
        border-radius: 8px;
        padding: 8px 8px 8px 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin: 15px 0 0 0;

        a {
            text-decoration-line: underline;
            cursor: pointer;

            background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        &__text {
            display: flex;
        }
    }

    &__button  {
        width: 100%;
        height: 58px;
        margin: 32px auto 0 auto;
    }

    &__restore {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;

        &__input {
            width: 352px;
            margin: 32px 0 0 0;
            border: 1px solid #313131;
            border-radius: 80px;

            & > div {
                color: $gray3;
            }

            input {
                color: $gray3;
                background-color: $black !important;
                border-radius: 80px;
                outline: none;
            }

            fieldset {
                display: none;
            }

            @media screen and (max-width: 600px) {
                width: 340px;
            }
        }
    }

    .gen-url {
        margin: 10px 0 0 0;
    }
}