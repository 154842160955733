@import "src/ui-kit/styles/vars";

.intro-section {
  position: relative;
  padding: 20px;
  background: $dark-gray;
  width: 550px;
  border-radius: 8px;
  margin: 80px auto 0 auto;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 1100px) {
    width: 450px;
  }

  @media screen and (max-width: 900px) {
    width: 380px;
  }

  @media screen and (max-width: 600px) {
    width: 280px;
  }

  @media screen and (max-width: 350px) {
    width: 250px;
  }
}