@import "../../ui-kit/styles/vars";

.twitter {
    width: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 12px;
    }

    &__title {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        padding-right: 10px;
        padding-bottom: 6px;

        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }
}