.network-input {
  &__paper {
    background-color: #313131 !important;
    border-radius: 8px !important;
    color: #FFFFFF !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }

  &__list {
    li {
      padding-bottom: 12px;
    }
    img {
      padding-right: 8px;
    }
  }
}