.header-info {
  display: flex;
  align-items: flex-start;

  &__logo img {
    height: 48px;
    width: 48px;
    margin-right: 8px;
    margin-top: 4px;

    @media screen and (max-width: 600px) {
      height: 40px;
      width: 40px;
    }
  }

  &__transferred {
    &__total {
      font-size: 20px;
      font-weight: 600;

      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }

    @media screen and (max-width: 600px) {
      font-size: 13px;
    }
  }
}