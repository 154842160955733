@import "../../ui-kit/styles/vars";

.send-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 356px;

    &:last-child {
        margin-top: 24px;
        margin-bottom: 48px;
    }

    padding: 32px 24px 40px 24px;

    background: $black;
    background-clip: padding-box;
    border: solid 1px transparent;
    position: relative;
    border-radius: 24px;

    &--disabled {
        .approve-form__content {
            pointer-events: none;
            cursor: not-allowed;

            .MuiInputBase-input {
                color: $gray1;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -1px;
        border-radius: inherit;
        background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
    }

    &__title {
        font-weight: 500;
        font-size: 32px;
        line-height: 42px;
        padding-bottom: 32px;
    }

    &__content {
        text-align: left;
        max-width: 352px;

        .MuiInputBase-input {
            color: white;
            display: flex;
            align-items: center;

            img {
                margin-right: 8px;
                border-radius: 6px;
            }
        }

        .MuiSelect-icon {
            right: 17px;
        }

        &__line {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__label {
        color: $gray4;
        padding-left: 16px;
    }

    & &__network-form,
    & &__address {
        width: 352px;
        margin: 20px 0;
        border: 1px solid #313131;
        border-radius: 80px;

        & > div {
            color: $gray1;
        }

        input {
            color: $gray1;
            background-color: $black !important;
            border-radius: 80px;
            outline: none;
        }

        fieldset {
            display: none;
        }

        @media screen and (max-width: 600px) {
            width: 340px;
        }
    }

    & &__token-form,
    & &__value {
        margin: 0 0 10px 0;

        & > div {
            color: $gray1;
        }

        fieldset {
            display: none;
        }
    }

    &__token-form__symbol {
        width: 90px;
        padding-right: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__token-form__logo {
        width: 20px;
        height: 20px;
    }

    &__token-form__loader {
        margin: 6px 16px 12px 16px;
    }

    & &__token-form {
        .MuiSelect-select {
            padding: 18px 50px 16px 16px !important;
        }

        &__balance {
            display: none;
        }
    }

    & &__value {
        max-width: 200px;
        input {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
        }
    }

    & &__token-balance {
        color: rgba(0,0,0,0.6);
        padding-top: 5px;
    }

    & &__token-name {
        position: absolute;
        left: 40px;
        margin-top: -14px;
        font-size: 12px;
    }

    &__paper {
        background-color: #313131 !important;
        border-radius: 8px !important;
        color: #FFFFFF !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 18px !important;
    }

    &__list {
        li {
            padding-bottom: 12px;
        }
        img {
            padding-right: 8px;
        }
    }

    &__max-button {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        background: #313131;
        border-radius: 40px;
        padding: 4px 7px;
        cursor: pointer;
    }

    &__token-dropdown {
        .approve-form__list {
            max-height: 340px;

            &::-webkit-scrollbar {
                width: 2px;
                background-color: #545454;
            }

            li {
                display: flex;
                justify-content: space-between;

                div:first-child {
                    min-width: 100px;
                    padding-right: 10px;
                }
            }
        }
    }

    &__hash {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin: 0 25px;

        padding: 16px 8px 16px 16px;

        a {
            text-decoration-line: underline;
            cursor: pointer;

            background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        &__text {
            display: flex;

            &--title {
                margin-right: 4px;

                @media screen and (max-width: 600px) {
                    width: 110px;
                }
            }
        }
    }

    .Mui-disabled {
        opacity: 0.7 !important;
        -webkit-text-fill-color: white !important;
    }

    @media screen and (max-width: 600px) {
        width: 330px;
    }
}