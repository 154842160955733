@import "src/ui-kit/styles/vars";

.intro-page {
  max-width: 810px;
  padding: 20px 20px 0 20px;

  &__title {
    font-weight: 500;
    font-size: 64px;
    line-height: 72px;
    padding-bottom: 4px;
    text-align: center;
    border-bottom: none !important;
  }

  &__section {
    &__title {
      font-size: 22px;
      line-height: 24px;
      font-weight: 500;
      padding-bottom: 12px;
    }

    &__description {
      padding-top: 2px;
      padding-bottom: 8px;
      color: $gray2;
    }
  }

  .gradient-href {
    border-bottom: 1px solid;
  }

  &__line {
    position: absolute;
    width: 250px;
    z-index: -1;

    margin-left: 110px;
    margin-top: -70px;

    @media screen and (max-width: 900px) {
      margin-left: 220px;
      margin-top: -30px;
    }

    @media screen and (max-width: 750px) {
      margin-left: 230px;
      width: 120px;
    }

    @media screen and (max-width: 600px) {
      margin-left: 130px;
    }

    &--rotate{
      position: absolute;
      width: 250px;
      z-index: -1;

      transform: rotate(270deg);
      translate: 100%;
      margin-top: -10px;
      margin-left: 140px;

      @media screen and (max-width: 900px) {
        margin-left: 60px;
        margin-top: -10px;
      }

      @media screen and (max-width: 750px) {
        margin-left: 60px;
        width: 120px;
      }

      @media screen and (max-width: 600px) {
        margin-left: 40px;
      }
      @media screen and (max-width: 380px) {
        margin-left: -40px;
      }
    }
  }
}